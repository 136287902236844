<nav>
    <img src="./assets/img/saku.png">
    <div class="nav-right-hand-side">
        <a href="#menu">MENU</a>
        <a href="#footer">LOCATIONS</a>
        <a href="#footer">ABOUT</a>
        <div class="phone-btn">
            <img src="./assets/img/phone.png">
            <a href="">0172 65468788</a>
        </div>
    </div>
</nav>