<section>
    <div>
        <h1>HOW TO ORDER</h1>
    </div>

    <div>
        <div class="order-section">
            <img src="./../../../assets/img/bowl_icon.jpg">
            <p>Pick<br>Your Noodle</p>
        </div>
        <div class="order-section">
            <img src="./../../../assets/img/broth_icon.png">
            <p>Pick<br>Your Broth</p>
        </div>
        <div class="order-section">
            <img src="./../../../assets/img/eggs_icon.jpg">
            <p>Add Your<br>Toppings</p>
        </div>
    </div>
</section>
