<section>
    <h1>IMPRINT</h1>
    <h2>Contact</h2>
    <h3>
        Nafi Müftüoglu <br>
        Musterstraße 21 <br>
        12345 Deutschland <br>
        Tel.: 1234567890 <br>
        e-Mail: mustermail.mail.de
    </h3>
    <a href="#main-page">Back to page</a>
</section>


