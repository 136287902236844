<section id="menu">
  <h1>OUR MENU</h1>

  <div class="our-menu-main">
    <div class="ramens">
      <img src="./../../../assets/img/r1.png" />
      <table>
        <thead>
          <h2>Tonkasu Ramen</h2>
        </thead>
        <tbody>
          <tr>
            <td>CHICKEN</td>
            <td>11,95€</td>
          </tr>
          <tr>
            <td>BEEF</td>
            <td>12,95€</td>
          </tr>
          <tr>
            <td>SHRIMP</td>
            <td>13,95€</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="ramens1">
      <img src="./../../../assets/img/r2.png" />
      <table>
        <thead><h2>Spicy Miso Ramen</h2></thead>
        <tbody>
          <tr>
            <td>CHICKEN</td>
            <td>11,95€</td>
          </tr>
          <tr>
            <td>BEEF</td>
            <td>12,95€</td>
          </tr>
          <tr>
            <td>SHRIMP</td>
            <td>13,95€</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="ramens">
      <img src="./../../../assets/img/r3.png" />
      <table>
        <thead><h2>Shio Ramen</h2></thead>
        <tbody>
          <tr>
            <td>CHICKEN</td>
            <td>11,95€</td>
          </tr>
          <tr>
            <td>BEEF</td>
            <td>12,95€</td>
          </tr>
          <tr>
            <td>SHRIMP</td>
            <td>13,95€</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>
