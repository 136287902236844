<section id="footer">  
    <div class="footer-left">
      <img src="./../../../../assets/img/saku.png" />
      <p class="left">SAKURA RAMEN</p>
    </div>
    <div class="footer-right">
        <a href="imprint">Imprint</a>
    </div>

</section>
